import { isPlatformBrowser, NgClass, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  signal,
  ViewChild,
  computed,
  input,
  OnInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { AuthenticationService } from 'sostereo-services';
import { TalkToACreativeModalComponent } from 'src/app/angular/modules/modals/components/brief-submission-modal/talk-to-a-creative-modal.component';
import { BtnComponent } from 'src/app/components/btn/btn.component';
import { CurrentUrlService } from 'src/app/angular/shared/services/current.url.service';
import { LogoLottieComponent } from 'src/app/components/logo-lottie/logo-lottie.component';
import { AccountRequestModalComponent } from 'src/app/angular/modules/modals/components/account-request-modal/account-request-modal.component';
import { SessionExpiredModalComponent } from 'src/app/angular/modules/modals/components/session-expired-modal/session-expired-modal.component';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';

@Component({
  selector: 'app-main-nav',
  standalone: true,
  imports: [
    RouterLinkWithHref,
    BtnComponent,
    NgClass,
    NgTemplateOutlet,
    TalkToACreativeModalComponent,
    LogoLottieComponent,
    AccountRequestModalComponent,
    SessionExpiredModalComponent,
  ],
  templateUrl: './main-nav.component.html',
  styleUrl: './main-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNavComponent implements OnInit, OnDestroy {
  @ViewChild('navBar', { static: true }) navBar: ElementRef;
  @ViewChild('talkToACreativeModal', { static: true }) talkToACreativeModal;
  @ViewChild('sessionExpiredModal', { static: true }) sessionExpiredModal;
  @ViewChild('accountRequestModal', { static: true }) accountRequestModal;
  private authenticationService = inject(AuthenticationService);
  private trackingService = inject(TrackingService);
  private currentUrlService = inject(CurrentUrlService);
  currentUser = this.authenticationService.getCurrentUser();
  mobileMenuOpen = signal<boolean>(false);
  showScrollMenu = signal<boolean>(false);
  absoluteScroll = signal<boolean>(true);
  blueHeaderView = input<boolean>();
  prevScrollPos = 0;
  currentUrl = computed(() => this.currentUrlService.value());
  isBrowser: any;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      document.body.scrollTop = 0;
      this.scrollBehavior();
    }
  }

  ngOnInit(): void {
    this.checkModals();
  }

  checkModals() {
    switch (this.currentUrl()) {
      case '/session-expired':
        this.sessionExpiredModal.showModal();
        break;
      case '/submit-brief':
        this.talkToACreativeModal.showModal();
        break;
      case '/submit-music':
        this.accountRequestModal.showModal();
        break;
      default:
        break;
    }
  }

  toggleMenu = () => {
    this.mobileMenuOpen.update((value) => !value);
  };

  private scrollBehavior() {
    document.body.addEventListener('scroll', () => {
      let currScrollPos = document.body.scrollTop;
      if (currScrollPos > 200) {
        this.mobileMenuOpen.set(false);
        if (currScrollPos > this.prevScrollPos) {
          this.navBar.nativeElement.style.transform = `translateY(-105%)`;
        } else {
          this.navBar.nativeElement.style.transform = `translateY(0%)`;
          this.showScrollMenu.set(true);
        }
        this.absoluteScroll.set(false);
      } else {
        if (currScrollPos === 0) {
          this.showScrollMenu.set(false);
        }
        this.absoluteScroll.set(true);
      }

      this.prevScrollPos = currScrollPos;
    });
  }

  showModal() {
    this.talkToACreativeModal.showModal();
  }

  ngOnDestroy(): void {
    if (this.isBrowser) {
      document.body.removeEventListener('scroll', () => {});
    }
  }

  public trackEvent(eventName, action, gaParams) {
    this.trackingService.track(
      eventName,
      {
        action: action,
        params: gaParams,
      },
      {
        ...gaParams,
      },
    );
  }
}
