<div
  class="flex items-center justify-center h-[70vh] lg:h-[100vh] relative z-[2] {{
    backgroundColor()
  }}"
>
  <div class="absolute w-[100%] flex">
    <app-shape-images
      class="w-[50%] transform scale-[2.5] translate-x-[-149%] origin-left md:transform-none md:w-[50%]"
      [image]="'/assets/images/disco-shapes/middle-right-shape.svg'"
      shape="middle-right"
      [shapeColor]="shapeColor()"
    ></app-shape-images>
    <app-shape-images
      class="w-[50%] transform scale-[2.5] translate-x-[149%] origin-right md:transform-none md:w-[50%]"
      [image]="'/assets/images/disco-shapes/middle-left-shape.svg'"
      shape="middle-left"
      [shapeColor]="shapeColor()"
    ></app-shape-images>
  </div>
  <div class="text-center relative z-10 text-primary">
    <h1 class="text-[56px] md:text-[100px] font-medium mb-4 font-primary">Get Started</h1>
    <p class="text-[16px] md:text-[20px] mb-6 font-secondary">
      Need help with an upcoming project? <br />
      Submit a brief and hear from us within an hour
    </p>
    <div class="flex justify-center space-x-4">
      <app-btn
        styles="hover:!bg-transparent"
        (clicked)="
          showModal();
          trackEvent('Talk to creative CallToAction (Get Started)', 'click', {
            event_type: 'Button Click',
            event_action: 'Talk to creative button clicked (Get Started)',
            event_value: 'Talk to creative'
          })
        "
        >Submit a brief</app-btn
      >
      <div
        class="transparent-meeting-button"
        (click)="
          trackEvent('Schedule a Call CallToAction', 'click', {
            event_type: 'Button Click',
            event_action: 'Schedule a Call button clicked',
            event_value: 'Schedule a Call'
          })
        "
      >
        <button id="transparent-button" style="display: none"></button>
      </div>
    </div>
  </div>
</div>
<app-talk-to-a-creative-modal #talkToACreativeModal></app-talk-to-a-creative-modal>
