<div *ngIf="isMobile || isTablet">
  <div class="show-for-mobile sidenav-bg" (click)="toggleSidenav()" *ngIf="showBlocker"></div>
  <div class="show-for-mobile sidenav-toggler-container">
    <span
      class="material-icons sidenav-toggler-icon"
      aria-hidden="true"
      (click)="toggleSidenav()"
      [ngClass]="
        !scrolled &&
        (currentRoute.includes('music-sync-licensing') ||
          ((currentRoute.includes('/discover') || currentRoute.includes('/search')) &&
            (!subdomain || tenantId !== 'sostereo')))
          ? 'color-light'
          : 'color-dark'
      "
      [attr.data-icon]="showBlocker ? 'expand_more' : 'more_vert'"
    ></span>
  </div>
</div>
<div
  [@inOutAnimation]
  id="sidenav"
  [ngStyle]="{
    'z-index': playerService.showSearchSidebar ? '7' : '5',
    'padding-bottom': isMobile || isTablet ? '50px' : '0'
  }"
  [ngClass]="{
    'sidenav-not-logged': !currentUser
  }"
>
  <div class="logo-container mt-2.5 mb-2.5" align="center" [routerLink]="'/'">
    <ng-container *ngIf="environment.name === 'stereospaces'">
      <img
        class="logo"
        [src]="
          subdomain && environment && environment.name === 'stereospaces'
            ? spacesLogo
            : company?.image
        "
        (click)="toggleSidenav()"
        onerror="this.src='./assets/images/logo-img.svg'"
        alt="stereospaces-logo"
      />
    </ng-container>
    <ng-container *ngIf="environment.name === 'sostereo'">
      <img
        class="logo"
        src="./assets/images/logo-img.svg"
        (click)="toggleSidenav()"
        height="60"
        width="60"
        alt="sostereo-logo"
      />
    </ng-container>
  </div>
  <!-- Workspaces container -->
  <div
    *ngIf="loggedUser?.workspaces?.length > 1"
    class="sidenav-item-container"
    align="center"
    dropdown
    placement="right"
    container="body"
  >
    <div class="sidenav-item pt-1 pb-1" dropdownToggle>
      <span
        aria-hidden="false"
        aria-label=""
        class="material-icons sidenav-google-icon"
        [attr.data-icon]="'workspaces'"
      ></span>
      <div class="sidenav-text-container">
        <h6 class="sidenav-text">Workspace</h6>
      </div>
    </div>
    <ul
      id="dropdown-basic"
      *dropdownMenu
      class="dropdown-menu workspace-options"
      [ngClass]="{
        'dropdown-menu-mobile-position': isMobile || isTablet
      }"
      role="menu"
      aria-labelledby="button-basic"
    >
      <li class="option-title">Current workspace</li>
      <li role="menuitem">
        <span class="dropdown-item pl-2.5" style="cursor: default">{{
          loggedUser.groups[0]?.replaceAll('-', ' ')
        }}</span>
      </li>
      <li class="option-divider"><div></div></li>
      <li class="option-title">My workspaces</li>
      <ng-container *ngFor="let workspace of loggedUser.workspaces; let i = index">
        <li *ngIf="workspace !== loggedUser.groups[0]" role="menuitem">
          <a class="dropdown-item pl-2.5" (click)="switchWorkspace(workspace)">{{
            workspace.replaceAll('-', ' ')
          }}</a>
        </li>
      </ng-container>
    </ul>
  </div>
  <!-- End Workspaces container -->
  <div
    class="sidenav-item-container preview-option"
    align="center"
    *ngIf="loggedAs || subdomainAccess"
    (mouseover)="changeText = true"
    (mouseout)="changeText = false"
    (click)="resetOriginalUser()"
    [tooltip]="previewUser ? previewUser.email : 'Access Account'"
    [tooltipPlacement]="'left'"
  >
    <div class="sidenav-item pt-1 pb-1">
      <span
        aria-hidden="false"
        aria-label=""
        class="sidenav-icon material-icons sidenav-google-icon"
        [attr.data-icon]="'supervised_user_circle'"
      ></span>
      <div class="sidenav-text-container">
        <h6 class="sidenav-text">{{ changeText ? 'Quit Preview' : 'Preview' }}</h6>
      </div>
    </div>
  </div>

  <div
    id="sidenav-container"
    class="sidenav-container lg:pl-[4px] border-r border-light"
    [ngClass]="{ 'sos-hidden-scrollbar': !(isMobile || isTablet) }"
    [ngStyle]="{
      'max-height': containerHeight,
      visisbility: isMobile || isTablet ? 'unset' : 'hidden',
    }"
  >
    <div
      [ngStyle]="{
        'max-height': containerHeight,
        'padding-bottom': loggedUser?.workspaces?.length > 1 ? '70px' : '20px'
      }"
      style="padding-bottom: 70px"
    >
      @for (option of sidenavOptions; track option.id; let i = $index){
      <div style="display: flex">
        <a
          *ngIf="!option.hide && !option.externalRoute"
          [routerLink]="option.route"
          [queryParamsHandling]="option.queryParamsHandling || ''"
          class="sidenav-item-container"
          align="center"
          (click)="onSidenavClick(option)"
          [ngClass]="{ 'active-option': option.selected }"
          [hidden]="
            !isAllowed(option.allowed, option.disallowed) ||
            (option.hideForMobile && (isMobile || isTablet))
          "
          [id]="option.id"
        >
          <ng-container *ngTemplateOutlet="sidenavIcons"> </ng-container>
        </a>
        <a
          *ngIf="!option.hide && option.externalRoute"
          class="sidenav-item-container"
          align="center"
          [href]="option.externalRoute"
          (click)="onSidenavClick(option)"
          [ngClass]="{ 'active-option': option.selected }"
          [hidden]="
            !isAllowed(option.allowed, option.disallowed) ||
            (option.hideForMobile && (isMobile || isTablet))
          "
          [id]="option.id"
        >
          <ng-container *ngTemplateOutlet="sidenavIcons"> </ng-container>
        </a>
        <ng-template #sidenavIcons>
          <span
            class="badge badge-info"
            *ngIf="option.badgeField && this[option.badgeField]"
            [@inOutAnimation]
          >
            {{ this[option.badgeField] }}
          </span>
          <div
            class="sidenav-item"
            *ngIf="
              !option.action ||
              (option.action &&
                currentRoute.indexOf('music-sync-licensing') !== -1 &&
                option.action &&
                currentRoute.indexOf('music-sync-licensing/playlist') === -1)
            "
            style="overflow: hidden"
          >
            <img
              *ngIf="option.iconRoute"
              [src]="option.iconRoute"
              class="sidenav-icon"
              alt="sidenav-icon"
            />
            <span
              *ngIf="option.googleIcon"
              aria-hidden="false"
              aria-label=""
              class="material-icons sidenav-google-icon"
              [attr.data-icon]="option.googleIcon"
            ></span>
            <ui-switch
              *ngIf="option.action && option.action === 'switch'"
              switchColor="#fcfcfc"
              size="small"
              [(ngModel)]="option.model"
              color="#2ececb"
              switchColor="#d9f1f3"
            ></ui-switch>
            <div class="sidenav-text-container">
              <h6 class="sidenav-text" style="white-space: break-spaces">{{ option.name }}</h6>
            </div>
          </div>
        </ng-template>
      </div>
      }

      <div
        class="sidenav-item-container"
        align="center"
        *ngIf="environment.name === 'sostereo' && isAllowed(['ListTiers'], [])"
        [routerLink]="'/company-settings'"
      >
        <div class="sidenav-item">
          <span aria-hidden="false" aria-label="" class="material-icons sidenav-google-icon"
            >dynamic_form</span
          >
          <!-- <img src="./assets/images/logos/stereo-brands-pink.png" alt="Nissan Logo" width="60px"> -->
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Brands</h6>
          </div>
        </div>
      </div>
      <div
        class="sidenav-item-container show-for-mobile"
        align="center"
        *ngIf="environment.name === 'sostereo' && !company?.theme?.hideHeader"
        [routerLink]="'/who-we-are'"
      >
        <div class="sidenav-item">
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'emoji_people'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Who We Are</h6>
          </div>
        </div>
      </div>
      <div
        class="sidenav-item-container show-for-mobile"
        align="center"
        *ngIf="!loggedUser && environment.name === 'sostereo' && !company?.theme?.hideHeader"
        (click)="accountRequestModal.showModal()"
      >
        <div class="sidenav-item">
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'stars'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Artist?</h6>
          </div>
        </div>
      </div>
      <div
        class="sidenav-item-container show-for-mobile"
        align="center"
        *ngIf="!loggedUser && environment.name === 'sostereo' && !company?.theme?.hideHeader"
        [routerLink]="'/blog/'"
      >
        <div class="sidenav-item">
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'feed'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">News</h6>
          </div>
        </div>
      </div>
      <div
        class="sidenav-item-container show-for-mobile"
        align="center"
        *ngIf="!loggedUser && isBrowser"
        (click)="loginAndSignUp(false)"
      >
        <div class="sidenav-item">
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'login'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Log In</h6>
          </div>
        </div>
      </div>
      <div
        class="sidenav-item-container show-for-mobile"
        align="center"
        *ngIf="!loggedUser && isBrowser"
        (click)="loginAndSignUp(true)"
      >
        <div class="sidenav-item">
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'portrait'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Sign Up</h6>
          </div>
        </div>
      </div>
      <div
        class="sidenav-item-container"
        align="center"
        *ngIf="loggedUser"
        (click)="onSidenavClick({ route: '/account-settings' })"
        [ngClass]="{ 'active-option': currentUrl === '/account-settings' }"
      >
        <a
          class="sidenav-item hover:no-underline focus:no-underline"
          routerLink="/account-settings"
        >
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'settings'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Account</h6>
          </div>
        </a>
      </div>
      <div
        class="sidenav-item-container"
        align="center"
        *ngIf="(tenantId !== 'sostereo' && isAllowed(['CreateIdentitie'], [])) || isAdmin"
        (click)="onSidenavClick({ route: '/company-settings/own' })"
        [ngClass]="{ 'active-option': currentUrl === '/company-settings' }"
      >
        <a
          class="sidenav-item hover:no-underline focus:no-underline"
          routerLink="/company-settings/own"
        >
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'build'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Set Up</h6>
          </div>
        </a>
      </div>
      <a
        href="https://help.stereospaces.com/"
        target="_blank"
        *ngIf="tenantId !== 'sostereo' && isAllowed(['UpdateSongAll'], [])"
      >
        <div class="sidenav-item-container" align="center">
          <div class="sidenav-item">
            <span
              aria-hidden="false"
              aria-label=""
              class="material-icons sidenav-google-icon"
              style="color: #fff"
              [attr.data-icon]="'help'"
            ></span>
            <div class="sidenav-text-container">
              <h6 class="sidenav-text">Help</h6>
            </div>
          </div>
        </div>
      </a>
      <div
        class="sidenav-item-container"
        align="center"
        id="sidenavLogOutBtn"
        *ngIf="loggedUser"
        (click)="logOut()"
      >
        <div class="sidenav-item">
          <span
            aria-hidden="false"
            aria-label=""
            class="material-icons sidenav-google-icon"
            [attr.data-icon]="'logout'"
          ></span>
          <div class="sidenav-text-container">
            <h6 class="sidenav-text">Log Out</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-talk-to-a-creative-modal #talkToACreativeModalComponent></app-talk-to-a-creative-modal>
<app-session-expired-modal #sessionExpiredModal></app-session-expired-modal>
<app-account-request-modal #accountRequestModal></app-account-request-modal>
