<div id="technicalIssues">
  <h1 style="padding: 40px;">We're experiencing technical<br> issues and are working to <br> resolve it</h1>
  <img src="./assets/images/logo-img.svg" alt="SoStereo logo" style="margin-bottom: 40px;">
  <div class="container">
    <h2>
      For any licensing or project inquiries reach out to
    </h2>
    <a class="underline-none contact-text" href="mailto:lindsey@sostereo.com" target="_top">
      lindsey&#64;sostereo.com</a>
    <br>
    <a class="underline-none contact-text" href="mailto:beto@sostereo.com" target="_top">
      beto&#64;sostereo.com</a>
  </div>
</div>