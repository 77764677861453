import { Routes } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { StereospacesAuthGuard } from '../../../guards/stereospaces-auth.guard';
import { MainLayoutComponent } from '../../shared/components/main-layout/main-layout.component';

const landingComponents = {
  sostereo: () => import('./components/landing.component').then((m) => m.LandingComponent),
  stereospaces: () =>
    import('./components/tenant-content/tenant-content.component').then(
      (m) => m.TenantContentComponent,
    ),
};

export const landingRoutes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: '',
        loadComponent:
          landingComponents[environment.name] ||
          (() => import('./components/landing.component').then((m) => m.LandingComponent)),
        canActivate: [StereospacesAuthGuard],
        title: 'SoStereo: Music solutions that add dimension to your story',
      },
      {
        path: 'landing',
        loadComponent:
          landingComponents[environment.name] ||
          (() => import('./components/landing.component').then((m) => m.LandingComponent)),
        canActivate: [StereospacesAuthGuard],
        title: 'SoStereo: Music solutions that add dimension to your story',
      },
      {
        path: 'session-expired',
        loadComponent:
          landingComponents[environment.name] ||
          (() => import('./components/landing.component').then((m) => m.LandingComponent)),
        canActivate: [StereospacesAuthGuard],
        title: 'SoStereo: Music solutions that add dimension to your story',
      },
      {
        path: 'submit-brief',
        loadComponent:
          landingComponents[environment.name] ||
          (() => import('./components/landing.component').then((m) => m.LandingComponent)),
        canActivate: [StereospacesAuthGuard],
        title: 'SoStereo: Music solutions that add dimension to your story',
      },
      {
        path: 'submit-music',
        loadComponent:
          landingComponents[environment.name] ||
          (() => import('./components/landing.component').then((m) => m.LandingComponent)),
        canActivate: [StereospacesAuthGuard],
        title: 'SoStereo: Music solutions that add dimension to your story',
      },
      {
        path: 'services',
        loadComponent: () =>
          import('../../modules/our-services/components/our-services/our-services.component').then(
            (m) => m.OurServicesComponent,
          ),
        title: 'Services',
      },
      {
        path: 'discover',
        loadChildren: () =>
          import('../../modules/discover/discover.module').then((m) => m.DiscoverModule),
        title: 'Discover',
      },
      {
        path: 'discover/',
        loadChildren: () =>
          import('../../modules/discover/discover.module').then((m) => m.DiscoverModule),
        title: 'Discover',
      },
      {
        path: 'search',
        loadChildren: () =>
          import('../../modules/discover/discover.module').then((m) => m.DiscoverModule),
        title: 'Search',
      },
      {
        path: 'services/sonic-analysis',
        loadComponent: () =>
          import('../../modules/our-services/pages/landing-sonic/landing-sonic.component').then(
            (c) => c.LandingSonicComponent,
          ),
        title: 'Sonic Analysis',
      },
      {
        path: 'services/licensed-music',
        loadComponent: () =>
          import('../../modules/our-services/pages/licensed-music/licensed-music.component').then(
            (c) => c.LicensedMusicComponent,
          ),
        title: 'Licensed Music',
      },
      {
        path: 'services/original-music',
        loadComponent: () =>
          import('../../modules/our-services/pages/original-music/original-music.component').then(
            (c) => c.OriginalMusicComponent,
          ),
        title: 'Original Music',
      },
      {
        path: 'services/recognizable-music',
        loadComponent: () =>
          import(
            '../../modules/our-services/pages/recognizable-music/recognizable-music.component'
          ).then((c) => c.RecognizableMusicComponent),
        title: 'Recognizable Music',
      },
      {
        path: 'stereobrands',
        redirectTo: '/services/stereobrands',
        pathMatch: 'full',
      },
      {
        path: 'services/stereobrands',
        loadComponent: () =>
          import(
            '../../modules/our-services/pages/landing-stereobrands/landing-stereobrands.component'
          ).then((c) => c.LandingStereobrandsComponent),
        title: 'Stereobrands',
      },
      {
        path: 'services/music-consulting',
        loadComponent: () =>
          import(
            '../../modules/our-services/pages/music-consulting/music-consulting.component'
          ).then((c) => c.MusicConsultingComponent),
        title: 'Music Consulting',
      },
      {
        path: 'our-work',
        loadComponent: () =>
          import(
            '../../modules/our-work-section/components/our-work-list/our-work-list.component'
          ).then((c) => c.OurWorkListComponent),
        title: 'Our Work',
      },
      {
        path: 'our-work/',
        loadComponent: () =>
          import(
            '../../modules/our-work-section/components/our-work-list/our-work-list.component'
          ).then((c) => c.OurWorkListComponent),
        title: 'Our Work',
      },
      {
        path: 'our-work/detail/:slug',
        loadComponent: () =>
          import(
            '../../modules/our-work-section/components/our-work-detail/our-work-detail.component'
          ).then((c) => c.OurWorkDetailComponent),
      },
      {
        path: 'who-we-are',
        loadComponent: () =>
          import('../about-us/components/about-us.component').then((c) => c.AboutUsComponent),
        title: 'Who We Are',
      },
      {
        path: 'who-we-are/',
        loadComponent: () =>
          import('../about-us/components/about-us.component').then((c) => c.AboutUsComponent),
        title: 'Who We Are',
      },
      {
        path: 'contact-us',
        loadComponent: () =>
          import('../../modules/our-services/components/contact-us/contact-us.component').then(
            (m) => m.ContactUsComponent,
          ),
        title: 'Contact Us',
      },
      {
        path: 'contact-us/',
        loadComponent: () =>
          import('../../modules/our-services/components/contact-us/contact-us.component').then(
            (m) => m.ContactUsComponent,
          ),
        title: 'Contact Us',
      },
    ],
  },
];
