import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { TalkToACreativeModalComponent } from 'src/app/angular/modules/modals/components/brief-submission-modal/talk-to-a-creative-modal.component';
import { ShapeImagesComponent } from 'src/app/angular/shared/components/shape-images/shape-images.component';
import { CommonService } from 'src/app/angular/shared/services/common.service';
import { TrackingService } from 'src/app/angular/shared/services/tracking.service';
import { BtnComponent } from 'src/app/components/btn/btn.component';

@Component({
  selector: 'app-get-started',
  standalone: true,
  imports: [BtnComponent, ShapeImagesComponent, TalkToACreativeModalComponent],
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetStartedComponent implements OnInit {
  @ViewChild('talkToACreativeModal', { static: true }) talkToACreativeModal;
  private trackingService = inject(TrackingService);
  isBrowser: boolean;

  constructor(private commonService: CommonService, @Inject(PLATFORM_ID) private platformId) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  backgroundColor = input<
    'bg-primary' | 'bg-light' | 'bg-dark' | 'bg-secondary' | 'bg-accent' | 'bg-default'
  >('bg-default');

  shapeColor = input<'primary' | 'light' | 'dark' | 'secondary' | 'accent' | 'default'>('default');

  ngOnInit(): void {
    if (this.isBrowser) {
      this.commonService.loadCalendarScript(
        'transparent-button',
        'Schedule a call',
        'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2ZAOdGggm19TRpLPQNeSdx6p1o0sB3ZCHY2mt46es35b8sznMkJXGiQbVoULwsrE29I5XMsu7V',
      );
    }
  }

  showModal() {
    this.talkToACreativeModal.showModal();
  }

  public trackEvent(eventName, action, gaParams) {
    this.trackingService.track(
      eventName,
      {
        action: action,
        params: gaParams,
      },
      {
        ...gaParams,
      },
    );
  }
}
